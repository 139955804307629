import React, { useState } from 'react';
import { ChevronRight, Shield, Users, Clock, Settings, Twitter, Facebook, Instagram, Linkedin, Menu, X } from 'lucide-react';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <header className="h-[80vh] relative">
        {/* Dark gradient overlay */}
        <div 
          className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 z-10"
          style={{
            backgroundImage: 'url("https://ldd-inc.com/wp-content/uploads/2022/03/Lou-FC-scaled-e1647544460577.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />

        {/* Navigation */}
        <nav className="max-w-6xl mx-auto px-4 py-6 flex justify-between items-center relative z-20">
          <div className="flex items-center">
            <h3 className="text-4xl md:text-6xl font-bold mb-4 text-shadow-lg text-white">MotoGoal</h3>
          </div>

          {/* Mobile Menu Button */}
          <button 
            onClick={toggleMenu}
            className="lg:hidden text-white z-20"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex space-x-8 text-white font-semibold">
            <a href="#about" className="hover:text-blue-200 transition-colors">About</a>
            <a href="#features" className="hover:text-blue-200 transition-colors">Features</a>
            <a href="#contact" className="hover:text-blue-200 transition-colors">Contact</a>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="absolute top-0 right-0 w-full h-screen bg-black/95 z-10 lg:hidden">
              <div className="flex flex-col items-center justify-center h-full space-y-8 text-white text-xl">
                <a href="#about" onClick={toggleMenu} className="hover:text-blue-200 transition-colors">About</a>
                <a href="#features" onClick={toggleMenu} className="hover:text-blue-200 transition-colors">Features</a>
                <a href="#contact" onClick={toggleMenu} className="hover:text-blue-200 transition-colors">Contact</a>
              </div>
            </div>
          )}
        </nav>

        {/* Hero Content */}
        <div className="max-w-6xl mx-auto px-4 h-[calc(80vh-5rem)] flex items-center relative z-20">
          <div className="text-white">
            <h1 className="text-4xl md:text-6xl font-bold mb-4 text-shadow-lg">MotoGoal</h1>
            <p className="text-xl md:text-2xl text-shadow-md">We move, you play!</p>
          </div>
        </div>
      </header>

      {/* About Section */}
      <section id="about" className="max-w-6xl mx-auto px-4 py-16">
        <h2 className="text-2xl md:text-3xl font-bold mb-8">Our Story</h2>
        <div className="space-y-6 text-gray-700">
          <p className="text-base md:text-lg">"Four freshmen go get the goal" are words that have stuck with MotoGoal founder Nelson Ennis since he got to fall camp in high school. When his NCAA playing career fell short to health complications, he started coaching at both the youth and high school levels.</p>
          <p className="text-base md:text-lg">While coaching his U-10 team it was never more evident to Ennis that there had to be an easier way to move the heavy and cumbersome 8-5 ft sized soccer goal.</p>
          <p className="text-base md:text-lg">Since then, Ennis has partnered with AIM Tech AI in California, to create prototypes and systems to provide solutions for all who need to move goals. AIM Tech's talented engineers, programmers, and AI specialists, combined with Ennis' 30 years in the event management space, will bring cutting edge technology to the sports industry.</p>
        </div>
      </section>

      {/* Problems Solved Section */}
      <section id="features" className="bg-gray-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold mb-12 text-center">Problems We Solve</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Card 1 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Users className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-bold mb-4">Coaches Training Sessions</h3>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Small Sided Games
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Goalie Wars
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Quick Transitions
                </li>
              </ul>
            </div>

            {/* Card 2 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Settings className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-bold mb-4">Field Maintenance</h3>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Avoid Turf Imprints
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  No Transition Off Mowers
                </li>
              </ul>
            </div>

            {/* Card 3 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Clock className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-bold mb-4">Tournament Directors</h3>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Multiple Age Groups
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Quick Field Size Changes
                </li>
              </ul>
            </div>

            {/* Card 4 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Shield className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-bold mb-4">Safety First</h3>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Avoid Accidents
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                  Let Players Play
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-12">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-wrap justify-center gap-8 mb-8">
            <a href="https://twitter.com/Motogoal_co" className="hover:text-blue-400">
              <Twitter className="w-6 h-6" />
            </a>
            <a href="https://facebook.com/Motogoal_co" className="hover:text-blue-400">
              <Facebook className="w-6 h-6" />
            </a>
            <a href="https://instagram.com/Motogoal_co" className="hover:text-blue-400">
              <Instagram className="w-6 h-6" />
            </a>
            <a href="https://linkedin.com/company/Motogoal_co" className="hover:text-blue-400">
              <Linkedin className="w-6 h-6" />
            </a>
            <a href="https://tiktok.com/@Motogoalco" className="hover:text-blue-400">
              <span className="font-bold">TikTok</span>
            </a>
          </div>
          <div className="text-center text-gray-400">
            © 2024 MotoGoal. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;